<template>
  <v-list-item v-if="title_show" @click="archivar" class="lessMargin">
    <v-list-item-icon>
      <v-icon style="margin-left: -3px; margin-top: -5px !important">
        mdi-close-circle-outline
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ $tc("deactivate") }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>

  <v-btn
    color="primary"
    outlined
    fab
    dark
    small
    v-on="on"
    v-else
    @click="archivar"
  >
    <v-icon>
      mdi-close-circle-outline
    </v-icon>
  </v-btn>
</template>

<script>
import * as moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "ArchivarTattooer",
  data() {
    return { today: null };
  },
  props: {
    tattooer: { type: Object, required: true },
    title: { type: String, required: true },
    title_show: { type: Boolean, default: true },
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    this.date = this.tattooer.tattooer_studios[0].to;
  },
  methods: {
    ...mapActions("tattooers", ["archivarTattooer"]),
    archivar() {
      this.$confirm(this.$t("are_you_sure_tattooer"), "", "").then(() => {
        let formData = {
          tattooer_id: this.tattooer.id,
          date: this.today,
        };
        //console.log(formData);
        this.archivarTattooer(formData).then((data) => {
          this.$emit("update");
          this.$alert(this.$t("tattooers.archivate"));
        });
      });
    },
  },
};
</script>
<style lang="sass">
.lessMargin
  .v-list-item__icon
    margin-right: 5px !important

  .v-icon, .svg-icon
    color: var(--v-gris1-lighten2) !important
</style>
